<template>
  <div>
    <b-img
      :src="finalUrl"
      class="h-100 avatar-image rounded-circle shadow"
    />
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default {
  name: 'MAvatar',
  components: {
    BImg,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    fallbackText: {
      type: String,
      required: true,
    },
  },
  computed: {
    finalUrl() {
      if (this.url != null && this.url.length > 0) {
        return this.url
      }

      return `https://ui-avatars.com/api/?name=${encodeURIComponent(this.fallbackText)}&background=1F719C&color=ffffff`
    },
  },
}
</script>

<style scoped>

</style>
