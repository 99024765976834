<template>
  <b-card>
    <care-pairing-therapist @pair-sub-complete="done" />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import CarePairingTherapist from '@/components/CarePairingTherapist.vue'

export default {
  name: 'CarePairingView',
  components: {
    BCard,
    CarePairingTherapist,
  },
  methods: {
    done() {
      this.$router.replace({
        name: 'home',
      })
    },
  },
}
</script>

<style scoped>
</style>
