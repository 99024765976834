<template>
  <b-card
    class="care-team-card text-center"
    :class="isSelected ? 'counselor-selected' : ''"
    @click="$emit('counselor-selected', counselor)"
  >
    <m-avatar
      :url="counselor.profilePicLocation"
      :fallback-text="counselor.alias"
      class="mb-2"
      :style="{ height: '114px' }"
    />
    <h3>{{ counselor.alias }}</h3>
    <h6 class="text-muted">
      {{ counselor.profession }}
    </h6>
    <b-badge
      v-for="(badge, index) in counselor.professionalCompetencies"
      :key="`badge${index}`"
      class="profile-badge mr-1 mb-1"
      variant="light-primary"
    >
      {{ badge }}
    </b-badge>

    <p class="text-muted">
      {{ counselor.externalBio }}
    </p>

    <h6>
      {{ $t('counselors.culturalCompetence') }}
    </h6>

    <b-badge
      v-for="(competence, index) in counselor.culturalCompetencies"
      :key="`competence${index}`"
      class="profile-badge mr-1"
      variant="light-primary"
    >
      {{ competence }}
    </b-badge>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
} from 'bootstrap-vue'
import MAvatar from '@/components/MAvatar.vue'

export default {
  name: 'Counselor',
  components: {
    MAvatar,
    BCard,
    BBadge,
  },
  props: {
    counselor: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables/_variables.scss";

.care-team-card {
  width: 350px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06) !important;
}

.counselor-selected {
  border: 2px solid #FFAC31;
}
</style>
